/**
 * @typedef {{label: string, value: string}} Filter
 *
 * @typedef {{pageType: string, categoryLevel_1: string, categoryLevel_2: string, categoryLevel_3: string}} GtmPageParameters
 *
 * @typedef {{
 *  event: string,
 *  envCountry: string,
 *  envCurrencyCode: string,
 *  envLanguage: string,
 *  envStoreId: string,
 *  envWork: string,
 *  page: GtmPageParameters,
 *  userEmail: string,
 *  userEmailSHA256: string,
 *  userId: string,
 *  userLoginState: string
 * }} GtmParameters
 */

var gtm = {
    active: true,
    buffer: {},
    events: {
        ADD_TO_CART: "addToCart",
        BANNER_CLICK: "clickBannière",
        CREATE_ACCOUNT: "creationCompte",
        FILTERS: "filtres",
        LOGIN: "connexion",
        MENU_CLICK: "clicMenuNiveau",
        MENU_FOOTER_CLICK: "clickMenuFooter",
        NEWSLETTER_SUBSCRIPTION: "newsletterSoumission",
        PRODUCT_ADD_WISHLIST: "productAddWishlist",
        PRODUCT_CHECKOUT: "productCheckout",
        PRODUCT_DETAIL: "productDetail",
        PRODUCT_IMPRESSIONS: "productImpressions",
        PRODUCT_CLICK: "productClick",
        PROMOTIONAL_BANNER_CLICK: "clickBandeau",
        PURCHASE: "orderConfirmation",
        RESET_PASSWORD: "reinitilisationMotPasse",
        SEARCH: "recherche",
        SOCIAL_NETWORK_CLICK: "clickSociauxRéseaux"
    },
    productsImpressionsFirstPosition: 0,
    productsImpressionsLastPosition: 0,

    /**
     * @description Initialize the datalayer
     *
     * @param {Object} parameters Parameters to initialize the datalayer
     */
    init: function (parameters) {
        this.active = parameters.hasConsent;
        if (!this.active) {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: parameters.event,

            // Environment information
            environnement: {
                language: parameters.envLanguage, // Current language of website
                country: parameters.envCountry, // Current country of website
                currencyCode: parameters.envCurrencyCode, // Current currency of website
                work: parameters.envWork, // Work environment
                store_id: parameters.envStoreId // Current store id of website
            },

            // Page information
            page: parameters.page,

            // User information
            user: {
                userLoginState: parameters.userLoginState, // 1 if user is logged, else 0
                userId: parameters.userId, // Salesforce user Id
                userEmail: parameters.userEmail, // Email of the user
                userEmailSHA256: parameters.userEmailSHA256, // Email of the user encrypted with SHA256
                userphone_number: parameters.userPhone,
                userphone_numberSHA256: parameters.userPhoneSHA256,
                userfirst_name: parameters.userFirstName,
                userfirst_nameSHA256: parameters.userFirstNameSHA256,
                userlast_name: parameters.userLastName,
                userlast_nameSHA256: parameters.userLastNameSHA256,
                useraddress_street: parameters.userAddressStreet,
                useraddress_city: parameters.userAddressCity,
                useraddress_region: parameters.userAddressRegion,
                useraddress_postalcode: parameters.userAddressPostalCode,
                useraddress_country: parameters.userAddressCountryCode
            }
        });
    },

    clipRect: function (rect) {
        return {
            left: Math.max(0, rect.left),
            top: Math.max(0, rect.top),
            right: Math.min(window.innerWidth, rect.right),
            bottom: Math.min(window.innerHeight, rect.bottom)
        };
    },

    calcArea: function (rect) {
        return (rect.right - rect.left) * (rect.bottom - rect.top);
    },

    calcOcclusion: function (element) {
        var rect = element.getBoundingClientRect();
        var clippedRect = this.clipRect(rect);
        return Math.max(0, this.calcArea(clippedRect) / this.calcArea(rect));
    },

    /**
     * @description Push the data of an event into the buffer
     *
     * @param {string} event Event name
     * @param {Object} data Event data
     * @param {boolean} autoPush Enables automatic push
     */
    addEventToBuffer: function (event, data, autoPush) {
        if (Object.hasOwnProperty.call(this.buffer, event)) {
            this.buffer[event].push(data);

            return;
        }

        this.buffer[event] = [data];

        if (autoPush) {
            this.pushMessage(event);
        }
    },

    /**
     * @description Push an action into the dataLayer
     *
     * @param {Object} action Action to push in the datalayer
     */
    pushAction: function (action) {
        if (this.active) {
            window.dataLayer.push(action);
        }
    },

    /**
     * @description Push an action with a buffer into the dataLayer
     *
     * @param {string} event Event to push in the datalayer
     */
    pushMessage: function (event) {
        var message = this.buildMessage(event);

        if (message && this.active) {
            window.dataLayer.push(message);
        }
    },

    /**
     * @description Build the message from the buffered data
     *
     * @param {string} event Event to transform into an object message
     *
     * @returns {Object|null} Object message built
     */
    buildMessage: function (event) {
        if (!Object.hasOwnProperty.call(this.buffer, event) || this.buffer[event].length === 0) {
            return null;
        }

        var message = {
            event: event
        };

        var items = this.buffer[event];
        var data = items[0];

        switch (event) {
            case this.events.ADD_TO_CART:
                message.locationAddToCart = data.location; // Change depending the location (panier/productdetail/product list/wishlist)
                message.productAddToCart = [data.product];
                break;

            case this.events.BANNER_CLICK:
                message.nomBannière = data.bannerName;
                message.typeBannière = "Category";
                break;

            case this.events.CREATE_ACCOUNT:
                message.tunnel = data.tunnel;
                break;

            case this.events.FILTERS:
                message.category = data.category;
                message.label = data.label;
                break;

            case this.events.LOGIN:
                message.tunnel = data.tunnel;
                break;

            case this.events.MENU_FOOTER_CLICK:
                message.text = data.text;
                break;

            case this.events.PRODUCT_ADD_WISHLIST:
                message.productAddWishlist = items;
                break;

            case this.events.PRODUCT_CHECKOUT:
                message.cartStep = data.currentStage;
                message.cartTotal_exclu_tax = data.cartTotalWithoutTax;
                message.cartTotal_inclu_tax = data.cartTotalWithTax;
                message.cartProductsTotal = data.cartProductsTotal;
                message.client_id = data.userId;
                message.store_id = data.storeId;
                message.order_id = data.orderId;
                message.productCheckout = data.products;
                break;

            case this.events.PRODUCT_DETAIL:
                message.productDetail = items;
                break;

            case this.events.PRODUCT_IMPRESSIONS:
                message.productsImpressions = items;
                break;

            case this.events.PRODUCT_CLICK:
                message.productClick = items;
                break;

            case this.events.PURCHASE:
                message.transactionShippingMethod = data.shippingMethod;
                message.transactionPaymentMethod = data.paymentMethod;
                message.transactionTotal_exclu_tax = data.merchandiseTotalNetPrice;
                message.transactionTotal_inclu_tax = data.merchandiseTotalGrossPrice;
                message.transactionCurrency = data.currency;
                message.transactionRevenu = data.totalGrossPrice;
                message.transactionId = data.orderId;
                message.transactionShipping = data.shippingFees;
                message.transactionTax = data.totalTax;
                message.transactionCoupon = data.coupon;
                message.userTotalPurchase = data.userTotalOrders;
                message.transactionProducts = data.products;
                break;

            case this.events.RESET_PASSWORD:
                message.tunnel = data.tunnel;
                break;

            case this.events.SEARCH:
                message.rechercheTerm = data.searchTerm;
                message.nombreRechercheTerm = data.searchCount;
                break;

            case this.events.SOCIAL_NETWORK_CLICK:
                message.text = data.text;
                break;

            default:
                break;
        }

        if (new RegExp(this.events.MENU_CLICK, "g").test(event)) {
            message.text = data.text;
        }

        delete this.buffer[event];

        return message;
    },

    /**
     * @description Add listeners on the filters of a product list.
     */
    addFilterListener: function () {
        var refinementNode = document.getElementsByClassName("js-refinement-container")[0];
        if (typeof refinementNode === "undefined") {
            return;
        }

        refinementNode.addEventListener("click", function (event) {
            var eventPath = event.composedPath();
            var refinement = null;
            for (var j = 0; j < eventPath.length; j++) {
                var node = eventPath[j];

                if (typeof node.className === "string" && node.className.includes("title")) {
                    continue;
                }

                if (node.localName === "button") {
                    refinement = node;
                    break;
                }
            }

            if (!refinement) {
                return;
            }

            var refinementLabelNode = refinement.querySelector(".js-refinement-label");
            if (refinementLabelNode.classList.contains("selected")) {
                return;
            }

            var refinementCardNode = refinement.closest(".card");
            var refinementCategoryNode = refinementCardNode.querySelector(".js-refinement-category");

            window.gtm.addEventToBuffer(window.gtm.events.FILTERS, { category: refinementCategoryNode.textContent.trim(), label: refinementLabelNode.textContent.trim() }, true);
        });
    },

    /**
     * @description Add a listener main menu
     */
    addMenuListener: function () {
        var navigation = document.getElementsByClassName("js_main_menu")[0];
        if (typeof navigator === "undefined") {
            return;
        }

        navigation.addEventListener("click", function (event) {
            var eventPath = event.composedPath();
            var linkNode = null;
            var level = null;

            for (var i = 0; i < eventPath.length; i++) {
                var node = eventPath[i];

                if (node.localName === "a") {
                    linkNode = node;
                }

                if (linkNode) {
                    break;
                }
            }

            if (linkNode) {
                if (linkNode.getAttribute("data-menu-level")) {
                    level = linkNode.getAttribute("data-menu-level");
                }

                var eventAction = "clicMenuNiveau" + level;
                var label = linkNode.textContent.trim();

                window.gtm.addEventToBuffer(eventAction, {
                    text: label
                }, true);
            }
        });
    },

    addProductDetailListener: function () {
        var addToCartButton = document.getElementsByClassName("add-to-cart")[0];

        addToCartButton.addEventListener("click", function () {
            var gtmDataInput = document.getElementsByClassName("js-gtm-product")[0];
            var gtmData = JSON.parse(gtmDataInput.dataset.gtm);

            var productBrand = document.getElementsByClassName("product-brand")[0];
            var productId = document.getElementsByClassName("product-id")[0];
            var productName = document.getElementById("product-detail-product-name");
            var sizeSelect = document.getElementsByClassName("select-size")[0];
            var size = sizeSelect.options[sizeSelect.selectedIndex].dataset.attrValue;

            var priceElement = document.getElementsByClassName("price")[0];
            var originalPriceElement = priceElement.querySelector(".old-price");
            var currentPriceElement = priceElement.querySelector(".value");

            var price = parseFloat(currentPriceElement.attributes.content.value);
            var originalPrice = originalPriceElement ? parseFloat(originalPriceElement.attributes.content.value) : price;
            var promotion = price - originalPrice;

            var productData = {
                brand: productBrand ? productBrand.textContent : "",
                category: gtmData.category,
                id: productId.textContent,
                name: productName.textContent,
                originPrice: String(originalPrice),
                price: String(price),
                promotion: String(promotion),
                quantity: "1",
                size: size ? size : "",
                sku: productId.textContent
            };

            this.addEventToBuffer(this.events.ADD_TO_CART, {
                location: "productdetail",
                product: productData
            }, true);
        }.bind(this));
    },

    addProductFromCartListener: function () {
        var quantitySelect = document.querySelectorAll(".line-item-quantity .quantity");
        var productData = {};

        if (quantitySelect) {
            quantitySelect.forEach(select => {
                var parentNode = select.closest(".product-line-item-details__description");
                select.setAttribute("data-current-value", select.value);

                select.addEventListener("change", function (event) {
                    var newValue = event.target.value;

                    var addToCartQuantity = 0;
                    if (event.target.getAttribute("data-current-value")) {
                        addToCartQuantity = newValue - event.target.getAttribute("data-current-value");
                        select.setAttribute("data-current-value", newValue);
                    }

                    if (addToCartQuantity > 0) {
                        var gtmDataInput = parentNode.querySelector(".js-gtm-product");
                        var gtmData = JSON.parse(gtmDataInput.dataset.gtm);

                        var price = parseFloat(gtmData.price);
                        var originalPrice = parseFloat(gtmData.originPrice);
                        var promotion = price - originalPrice;

                        productData = {
                            brand: gtmData.brand,
                            category: gtmData.category,
                            id: gtmData.id,
                            name: gtmData.name,
                            originPrice: String(originalPrice),
                            price: String(price),
                            promotion: String(promotion),
                            quantity: addToCartQuantity,
                            size: gtmData.size,
                            sku: gtmData.id
                        };

                        this.addEventToBuffer(this.events.ADD_TO_CART, {
                            location: "panier",
                            product: productData
                        }, true);
                    }
                }.bind(this));
            });
        }
    },

    addProductAddWishlistListener: function () {
        var addToWishlistButton = document.querySelectorAll(".add-to-wish-list");
        var productData = {};

        if (addToWishlistButton) {
            addToWishlistButton.forEach(button => {
                button.addEventListener("click", function (event) {
                    if (event.target.closest(".product-detail")) {
                        var gtmDataInput = document.getElementsByClassName("js-gtm-product")[0];
                        productData = JSON.parse(gtmDataInput.dataset.gtm);
                        var sizeSelect = document.getElementsByClassName("select-size")[0];
                        var productId = document.getElementsByClassName("product-id")[0];
                        productData = {
                            ...productData,
                            size: sizeSelect.options[sizeSelect.selectedIndex].dataset.attrValue || "",
                            sku: productId.textContent
                        };
                    } else {
                        var productTile = event.target.closest(".product");
                        var productTileData = productTile.querySelector(".js-product-tile");

                        productData = JSON.parse(productTileData.dataset.gtm);
                        if (productData.list === "") {
                            var pageTitle = document.getElementsByClassName("h2")[0];
                            productData.list = pageTitle.textContent.trim();
                        }
                        productData = {
                            ...productData,
                            size: "",
                            sku: productData.id
                        };
                    }

                    if (!event.target.closest(".wishlisted")) {
                        this.addEventToBuffer(this.events.PRODUCT_ADD_WISHLIST, productData, true);
                    }
                }.bind(this));
            });
        }
    },

    addProductListListener: function (tileContainerClass) {
        var tileContainer = document.getElementsByClassName(tileContainerClass)[0];

        if (typeof tileContainer === "undefined") {
            return;
        }

        tileContainer.addEventListener("click", function (event) {
            var flagNode = event.composedPath().find(function (node) {
                return typeof node.className === "string" && node.className.includes("js-product-link");
            });

            if (!flagNode) {
                return;
            }

            var productTile = event.target.closest(".product");
            var productTileData = productTile.querySelector(".js-product-tile");

            var productData = JSON.parse(productTileData.dataset.gtm);
            if (productData.list === "") {
                var pageTitle = document.getElementsByClassName("h2")[0];
                productData.list = pageTitle.textContent.trim();
            }

            this.addEventToBuffer(this.events.PRODUCT_CLICK, productData, true);
        }.bind(this));
    },

    addScrollListener: function () {
        window.onscroll = function () {
            window.gtm.sendProductImpression();
        };
    },

    sendProductImpression: function () {
        var currentFirstPosition = 0;
        var currentLastPosition = 0;
        var elements = document.getElementsByClassName("product");
        var impressionEvent = window.gtm.events.PRODUCT_IMPRESSIONS;
        for (var i = 0; i < elements.length; i++) {
            var elem = elements[i];
            var visibilityElem = Math.round(window.gtm.calcOcclusion(elem) * 100);

            if (visibilityElem >= 70) {
                var productTileData = elem.querySelector(".js-product-tile");
                var productData = JSON.parse(productTileData.dataset.gtm);
                if (productData.list === "") {
                    var pageTitle = document.getElementsByClassName("h2")[0];
                    productData.list = pageTitle.textContent.trim();
                }
                window.gtm.addEventToBuffer(impressionEvent, productData);

                if (window.gtm.buffer[impressionEvent].length === 1) {
                    currentFirstPosition = parseInt(productData.position, 10);
                }

                currentLastPosition = parseInt(productData.position, 10);
            }

            if (window.gtm.buffer[impressionEvent] && window.gtm.buffer[impressionEvent].length >= 15) {
                break;
            }
        }

        // Check buffered impressions
        if ((currentFirstPosition === 0 && currentLastPosition === 0) ||
            (window.gtm.productsImpressionsFirstPosition === currentFirstPosition &&
                window.gtm.productsImpressionsLastPosition === currentLastPosition)
        ) {
            delete this.buffer[impressionEvent];

            return;
        }

        window.gtm.pushMessage(impressionEvent);
        window.gtm.productsImpressionsFirstPosition = currentFirstPosition;
        window.gtm.productsImpressionsLastPosition = currentLastPosition;
    },

    /**
     * @description Add a listener to the layer (login)
     */
    loginListener: function () {
        var loginData = {
            tunnel: false
        };

        var loginButton = document.getElementById("jsLoginButton");
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var urlFrom = urlParams.get("from");

        if (!sessionStorage.getItem("login")) {
            if (urlFrom === "cart") {
                loginData = {
                    tunnel: true
                };
            }

            if (loginButton) {
                loginButton.addEventListener("click", function () {
                    sessionStorage.setItem("login", JSON.stringify(loginData));
                });
            }
        } else {
            loginData = JSON.parse(sessionStorage.getItem("login"));
            this.addEventToBuffer(this.events.LOGIN, loginData, true);
            sessionStorage.removeItem("login");
        }
    },

    /**
     * @description Add a listener to the layer (create account)
     */
    createAccountListener: function () {
        var createAccountData = {
            tunnel: false
        };

        var createAccountButton = document.getElementById("jsCreateAccountButton");
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var urlFrom = urlParams.get("from");

        if (!sessionStorage.getItem("createAccount")) {
            if (urlFrom === "cart") {
                createAccountData = {
                    tunnel: true
                };
            }

            if (createAccountButton) {
                createAccountButton.addEventListener("click", function () {
                    sessionStorage.setItem("createAccount", JSON.stringify(createAccountData));
                });
            }
        } else {
            createAccountData = JSON.parse(sessionStorage.getItem("createAccount"));
            this.addEventToBuffer(this.events.CREATE_ACCOUNT, createAccountData, true);
            sessionStorage.removeItem("createAccount");
        }
    },

    bannerListener: function () {
        var banners = document.querySelectorAll(".marketing-tile");

        for (var i = 0; i < banners.length; i++) {
            var banner = banners[i];
            var bannerLink = banner.getElementsByTagName("a");

            if (bannerLink.length > 0) {
                banner.addEventListener("click", function (event) {
                    var titleBanner = event.target.querySelector("h3");
                    var titleBannerText = titleBanner.textContent.replace(/(\r\n|\n|\r)/gm, " ");
                    var bannerData = {
                        bannerName: titleBannerText
                    };

                    this.addEventToBuffer(this.events.BANNER_CLICK, bannerData, true);
                }.bind(this));
            }
        }

    },

    /**
     * @description Add a listener to the layer (menu footer)
     */
    addClickMenuFooterListener: function () {
        var footerLink = document.querySelectorAll(".footer-main__list__item > a");
        var clickData = {};

        if (footerLink) {
            footerLink.forEach(link => {
                link.addEventListener("click", function (event) {
                    clickData = {
                        text: event.target.textContent
                    };

                    this.addEventToBuffer(this.events.MENU_FOOTER_CLICK, clickData, true);
                }.bind(this));
            });
        }
    },

    /**
     * @description Add a listener to the layer (social networks)
     */
    addClickSocialNetworksListener: function () {
        var socialNetwork = document.querySelectorAll(".footer-main__social-media__list a");
        var clickData = {};

        if (socialNetwork) {
            socialNetwork.forEach(icon => {
                icon.addEventListener("click", function (event) {
                    clickData = {
                        text: event.target.title
                    };

                    this.addEventToBuffer(this.events.SOCIAL_NETWORK_CLICK, clickData, true);
                }.bind(this));
            });
        }
    },

    /**
     * @description Add a listener to the layer (social networks)
     */
    addClickPromotionalBanner: function () {
        var promotionalBanner = document.getElementsByClassName("header-top-banner")[0];

        if (promotionalBanner) {
            promotionalBanner.addEventListener("click", function () {
                this.addEventToBuffer(this.events.PROMOTIONAL_BANNER_CLICK, null, true);
            }.bind(this));
        }
    },

    /**
     * @description Add a listener to the layer (checkout steps)
     */
    addCheckoutListener: function (data) {
        this.addEventToBuffer(this.events.PRODUCT_CHECKOUT, data, true);

        var submitShippingButton = document.getElementsByClassName("submit-shipping")[0];
        var triggerShippingButton = document.getElementsByClassName("trigger-shipping")[0];
        var submitButtons = [];

        if (submitShippingButton && triggerShippingButton) {
            submitButtons = [submitShippingButton, triggerShippingButton];
        }

        if (submitButtons.length) {
            submitButtons.forEach((button) => {
                button.addEventListener("click", () => {
                    setTimeout(() => {
                        var newCheckoutStage = document.getElementById("checkout-main").dataset.checkoutStage;

                        if (data.currentStage !== newCheckoutStage) {
                            data.currentStage = newCheckoutStage;
                            this.addEventToBuffer(this.events.PRODUCT_CHECKOUT, data, true);
                        }
                    }, 500);
                });
            });
        }
    },

    /**
     * @description Load gtm script
     *
     * @param {string} gtmId GTM identifier
     */
    loadGtm: function (gtmId) {
        (function (w, d, s, l, i) { // eslint-disable-line no-unused-vars
            w[l] = w[l] || [];
            w[l].push({
                "gtm.start": new Date().getTime(),
                event: "gtm.js"
            });
            var f = d.getElementsByTagName(s)[0];
            var j = d.createElement(s);
            var dl = l !== "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://metrics.kidsaround.us/7qcd7r11ks.js?" + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", gtmId);
    }
};

window.gtm = gtm;
